<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Stores</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Add New</button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Merchant</th>
                                                    <th>Hub</th>
                                                    <th>Address</th>
                                                    <th>Note</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Merchant</th>
                                                    <th>Hub</th>
                                                    <th>Address</th>
                                                    <th>Note</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ item.name }}</td>
                                                    <td>{{ item.mobile }}</td>
                                                    <td>{{ item.merchant.business }}</td>
                                                    <td>{{ item.hub.name }}</td>
                                                    <td>{{ item.address }}</td>
                                                    <td>{{ item.note }}</td>
                                                    <td style="text-align:center;">
                                                        <button class="btn btn-warning btn-sm mr-1" @click="editStore(item)"><i class="fas fa-pencil-alt"></i></button>
                                                        <button class="btn btn-danger btn-sm"  @click="deleteStore(key, item.id)"><i class="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Create Pickup Store</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createStore)">  
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>  
                                                    <select2 v-model="form.merchant_id" placeholder="Select" :options="commonObj.merchantList"></select2>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>  
                                                    <select2 v-model="form.hub_id" placeholder="Select" :options="commonObj.hubList"></select2>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Name">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min_value:11">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="mobile" v-model="form.mobile" class="form-control" placeholder="Mobile No">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="address" v-model="form.address" class="form-control" placeholder="Address">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Note" vid="note" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="note">Note <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="note" v-model="form.note" class="form-control" placeholder="Note">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>                                
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="createStore">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="editModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Pickup Store</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateStore)">  
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>  
                                                    <select2 v-model="form_edit.merchant_id" placeholder="Select" :options="commonObj.merchantList"></select2>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>  
                                                    <select2 v-model="form_edit.hub_id" placeholder="Select" :options="commonObj.hubList"></select2>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Name">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min_value:11">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile No">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Address">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Note" vid="note" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="note">Note <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="note" v-model="form_edit.note" class="form-control" placeholder="Note">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>                                
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateStore">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyStore">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
import config from '@/config'
import Select2 from 'v-select2-component'
export default {
    name:'Store',
    components:{
        'select2': Select2
    },
    data(){
        return {
            loader: false,
            merchants:{},
            partners:{},
            stores:{
                data: []
            },
            form:{
                merchant_id : 0,
                hub_id  : 0,
                name : '',
                mobile: '',
                address : '',
                note : '',
            },
            form_edit:'',
            errors:[],
            createModal:false,
            editModal:false,
            deleteModal:false,
            store_id:'',
            key:'',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created(){
        this.loadData();
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadData()
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        },
        commonObj () {
            return this.$store.state.commonObj
        }
    },
    methods:{
        searchData () {
            this.loadData()
        },
        async loadData() {               
            this.loader = true 
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
            const response = await config.getData('/store', params)
            this.loader = false
            if (response.status == 200){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        showCreateModal(){
            this.createModal = true;
        },
        async createStore() {
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/store/store', this.form)
            this.loader = false
            if (response.status == 201) {
                this.$store.dispatch('commonObjLoad', true)
                this.createModal = false;
                this.form = ''           
                this.$toast.success({
                    title: 'Success',
                    message: 'Store created successfully',
                    color: '#218838'
                }) 
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong'
                })   
            }
            this.$store.dispatch('stateReload', false)
        },
        cancelModal(){
            this.createModal = false;
            this.editModal = false;
            this.deleteModal = false;
        },
        editStore(store){
            this.editModal = true;
            this.form_edit = store;
        },
        async updateStore(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/store/update', this.form_edit)
            this.loader = false
            if (response.status == 201) {
                this.$store.dispatch('commonObjLoad', true)
                this.editModal = false;            
                this.$toast.success({
                    title: 'Success',
                    message: 'Store updated successfully',
                    color: '#218838'
                })  
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong'
                })  
            }
            this.$store.dispatch('stateReload', false)
        },
        deleteStore(key, store_id){
            this.store_id = store_id;
            this.key = key;
            this.deleteModal = true;
        },
        async destroyStore(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            await config.deleteData('/store/destroy/'+ this.store_id)
            this.loader = false           
            this.deleteModal = false
            this.$toast.success({
                title: 'Success',
                message: 'Stpre deleted successfully',
                color: '#218838'
            }) 
            this.$store.dispatch('stateReload', false)
        }
    }
}
</script>